<template>
  <div class="flex flex-col w-full">
    <card class="w-full flex flex-col mt-2 p-5">
      <div class="w-full gap-5 mb-6 flex">
        <Card class="w-1/4 py-6 px-4">
          <div class="flex justify-between h-11">
            <Icon
              class-name="text-mediumSeaGreen -mb-2"
              icon-name="template-union"
              size="l"
            />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase"
                >Total Work Count</span
              >
              <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                totalWorkCount
              }}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card class="w-1/4 py-6 px-4">
          <div class="flex justify-between h-11">
            <Icon
              class-name="text-blueCrayola -mb-2"
              icon-name="icon-requests"
              size="l"
            />
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet"
                >Total number of hours</span
              >
              <span class="text-lg font-extrabold text-blueCrayola">{{
                totalWorkHours
              }}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card class="w-1/4 py-6 px-4">
          <div class="flex justify-between h-11">
            <card class="p-2" style="height: 40px">
              <Icon
                class-name="text-chartPurple -mb-2"
                icon-name="icon-money"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">
                Total Customers Impacted
              </span>
              <span class="text-lg font-extrabold text-chartPurple">{{
                totalCustomersImpacted
              }}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
      </div>
      <template v-slot:footer>
        <CardFooter
          :buttoncard="isEligible"
          buttontext="New Request"
          module="overtime"
          printcard
          reloadcard
          @reload="reload"
          @modalRequest="addRequest()"
        />
      </template>
    </card>
    <card class="p-5 mt-1">
      <div class="flex-col">
        <div class="w-full">
          <Table
            v-if="requests.length > 0 || loading"
            :headers="tableHeaders"
            :items="requests"
            aria-label="overtime-table"
            class="w-full mt-4 mb-4"
            :has-checkbox="false"
            :has-number="false"
            :loading="loading"
            :pagination-list="metaData"
            @page="handlePage($event)"
            page-sync
            @itemsPerPage="handleItemsPerPage($event)"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.category">
                <span> {{ item.data.category }} </span>
              </div>
              <div v-if="item.plannedTask">
                <span> {{ item.data.plannedTask }} </span>
              </div>
              <div v-if="item.description">
                <span> {{ item.data.description }} </span>
              </div>
              <div v-else-if="item.startDate">
                <span>
                  {{
                    $DATEFORMAT(new Date(item.data.startDate), "MMMM dd, yyyy")
                  }}
                </span>
              </div>
              <div v-else-if="item.endDate">
                <span>
                  {{
                    $DATEFORMAT(new Date(item.data.endDate), "MMMM dd, yyyy")
                  }}
                </span>
              </div>
              <div v-else-if="item.workHours">
                <span>
                  {{ setTimeData(Math.round(item.data.workHours * 10) / 10) }}
                </span>
              </div>

              <div v-if="item.preApprovalStatus">
                <div
                  class="w-auto flex flex-col"
                  v-if="item.data.preApprovalStatus === 'approved'"
                >
                  <Tag
                    class="py-2 px-2 text-mediumSeaGreen bg-light-sea-green text-sm capitalize"
                  >
                    <p class="text-sm font-semibold capitalize text-center">
                      {{ item.data.preApprovalStatus }}
                    </p>
                  </Tag>
                </div>

                <div
                  class="w-auto flex flex-col"
                  v-if="item.data.preApprovalStatus === 'pending'"
                >
                  <Tag
                    class="py-2 px-2 text-carrotOrange bgCarrot text-sm capitalize"
                  >
                    <p class="text-sm font-semibold capitalize text-center">
                      {{ item.data.preApprovalStatus }}
                    </p>
                  </Tag>
                </div>

                <div
                  class="w-auto flex flex-col"
                  v-if="item.data.preApprovalStatus === 'disapproved'"
                >
                  <Tag
                    class="py-2 px-2 text-desire bg-light-flame-red text-sm capitalize"
                  >
                    <p class="text-sm font-semibold capitalize text-center">
                      {{ item.data.preApprovalStatus }}
                    </p>
                  </Tag>
                </div>

                <div
                  class="w-auto flex flex-col"
                  v-if="item.data.preApprovalStatus === 'draft'"
                >
                  <Tag class="py-2 px-2 text-jet bg-ash text-sm capitalize">
                    <p class="text-sm font-semibold capitalize text-center">
                      {{ item.data.preApprovalStatus }}
                    </p>
                  </Tag>
                </div>
              </div>
              <div
                v-else-if="item.id && item.data.preApprovalStatus === 'draft'"
              >
                <Menu
                  v-if="!hasSubscriptionExpired()"
                  left
                  top="-150"
                  margin="31"
                  class="my-2 p-0"
                >
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div style="width: 116px; height: 81px" class="py-3 px-2">
                    <div
                      class="flex py-1 px-3 more h-8 cursor-pointer"
                      @click="handleEdit(item.data)"
                    >
                      <icon
                        icon-name="edit"
                        class-name="text-blueCrayola mr-2"
                        size="xsm"
                      />
                      <p class="pt-1">Edit</p>
                    </div>
                    <div
                      class="flex px-3 py-1 more h-8 cursor-pointer"
                      @click="handleDelete(item.data)"
                    >
                      <icon
                        icon-name="delete"
                        class-name="text-desire mr-2"
                        size="xsm"
                      />
                      <p class="pt-1">Delete</p>
                    </div>
                  </div>
                </Menu>
              </div>
            </template>
          </Table>
          <div
            v-else
            class="w-full flex flex-col mt-4 p-2 justify-center items-center"
          >
            <p class="text-base my-2">
              There are no pending overtime requests at the moment.
            </p>
          </div>
        </div>
      </div>
    </card>
    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <RightSideBar
        v-if="requestModal"
        @submit="
          enableEdit
            ? handleSubmit(editPlannedWork('save'))
            : handleSubmit(addPlannedWork('save'))
        "
        @close="
          enableEdit
            ? handleSubmit(editPlannedWork('draft'))
            : handleSubmit(addPlannedWork('draft'))
        "
        button-class="bg-dynamicBackBtn text-white"
        :cancel="enableEdit ? `Update Draft` : `Save as Draft`"
        submit="Submit"
        :disabled-button="disableBtn"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <back-button label="" @onClick="closeModal()" variant="secondary" />
            <p class="text-darkPurple mt-4">
              {{ enableEdit ? "Edit Request" : "New Request" }}
            </p>
          </div>
        </template>
        <p class="font-semibold text-darkPurple">
          {{
            enableEdit
              ? "Edit your overtime planned work"
              : "Create a new overtime planned work"
          }}
        </p>
        <c-select
          placeholder="--Select--"
          :options="items"
          variant="w-full"
          label="Category"
          :rules="['required']"
          class="mr-2 mt-2 mb-4"
          v-model="request.paySettingId"
        />
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Work Title"
          :rules="['required']"
          class="mr-2 mt-2 mb-4"
          v-model="request.plannedTask"
        />
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Description"
          class="mr-2 mt-2 mb-4"
          v-model="request.description"
        />
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Impact"
          class="mr-2 mt-2 mb-4"
          v-model="request.impact"
        />
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Total Customers Impacted"
          class="mr-2 mt-2 mb-4"
          type="number"
          v-model="request.totalCustomersImpacted"
        />
        <c-select
          placeholder="--Select--"
          :options="severityItem"
          variant="w-full"
          label="Severity"
          :rules="['required']"
          class="mr-2 mt-2 mb-4"
          v-model="request.severity"
        />
        <c-text
          placeholder="--Enter--"
          variant="w-full"
          label="Severity Comment"
          class="mr-2 mt-2"
          v-model="request.severityComment"
        />
        <p class="mt-4 text-sm text-darkPurple">Duration</p>
        <div class="w-full flex flex-col">
          <div class="w-1/6 text-base ml-3 mr-1 mt-4 text-romanSilver">
            From
          </div>
          <div class="w-full flex">
            <div class="w-7/12 flex mt-1">
              <div class="w-full date-flex">
                <datepicker
                  placeholder="--Select date--"
                  input-class="date-input"
                  style="width: 100%; outline: none"
                  v-model="startDate"
                />
              </div>
            </div>
            <div class="w-1/12 mt-7" />
            <div class="w-3/12 flex flex-col -mt-4">
              <label class="text-romanSilver">Time</label>
              <div class="date-flex w-full">
                <input
                  type="time"
                  placeholder="Start time"
                  class="mr-2 mt-2 w-full"
                  v-model="startTime"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-col">
          <div class="w-1/6 text-base ml-3 mr-1 mt-4 text-romanSilver">To</div>
          <div class="w-full flex">
            <div class="w-7/12 flex mt-1">
              <div class="w-full date-flex">
                <datepicker
                  placeholder="--Select date--"
                  input-class="date-input"
                  style="width: 100%; outline: none"
                  v-model="endDate"
                  :disabled-dates="disabledEndDates"
                />
              </div>
            </div>
            <div class="w-1/12 mt-7" />
            <div class="w-3/12 flex flex-col -mt-4">
              <label class="text-romanSilver">Time</label>
              <div class="date-flex w-full">
                <input type="time" class="mr-2 mt-2 w-full" v-model="endTime" />
              </div>
            </div>
          </div>
          <div
            class="w-11/12 flex mt-3 ml-4 p-2 rounded mb-4 justify-center items-center"
            style="background: #f7f7ff"
          >
            <p class="font-semibold text-xl text-romanSilver">
              {{ numberDays }}
            </p>
          </div>
        </div>
        <div
          class="w-full border-dashed border mt-2 mb-8"
          style="height: 1px; border-color: #878e99"
        />
      </RightSideBar>
    </ValidationObserver>

    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Draft?
          </p>
          <div class="flex">
            <Button class="bg-dynamicBackBtn text-white" @click="deleteDraft">
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Datepicker from "vuejs-datepicker";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import Button from "@/components/Button";
import Tag from "@/components/Tag";
import Menu from "@/components/Menu";
import RightSideBar from "@/components/RightSideBar";
import Modal from "@/components/Modal";

export default {
  name: "PlannedWorks",
  components: {
    Card,
    Icon,
    ValidationObserver,
    Button,
    Tag,
    CardFooter,
    Modal,
    Menu,
    CText,
    RightSideBar,
    Datepicker,
    BackButton,
    CSelect,
    Table,
  },
  props: {
    isEligible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      requestModal: false,
      dialogDel: false,
      disableBtn: true,
      response: "",
      responseBg: "",
      success: false,
      requests: [],
      totalWorkHours: 0,
      totalCustomersImpacted: 0,
      totalWorkCount: 0,
      numberDays: 0,
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      request: {
        paySettingId: "",
        plannedTask: "",
        description: "",
        descriptionFiles: null,
        impact: null,
        impactFiles: null,
        totalCustomersImpacted: null,
        severity: "",
        severityComment: "",
        startDate: "",
        startHour: "",
        endDate: "",
        endHour: "",
        employeeId: "",
        startMinute: "",
        endMinute: "",
        isPreApproval: true,
      },
      requestId: "",
      overtimeId: "",
      enableEdit: false,
      items: [],
      employeeId: "",
      loading: true,
      tableHeaders: [
        { title: "Category", value: "category" },
        { title: "Work Title", value: "plannedTask" },
        { title: "Description", value: "description" },
        { title: "Start Date", value: "startDate" },
        { title: "End Date", value: "endDate" },
        { title: "No. of Hours", value: "workHours" },
        { title: "Status", value: "preApprovalStatus" },
        { title: "", value: "id", image: true },
      ],
      severityItem: [
        { id: "critical", name: "Critical" },
        { id: "high", name: "High" },
        { id: "medium", name: "Medium" },
        { id: "low", name: "Low" },
      ],
      disabledEndDates: {
        to: "", // Disable all dates up to specific date
      },
      disabledStartDates: {
        to: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24), // Disable all dates up to specific date(yesterday)
      },
    };
  },

  watch: {
    startDate(value) {
      this.request.startDate = this.$DATEFORMAT(new Date(value), "yyyy-MM-dd");
      this.disabledEndDates.to = new Date(new Date(value).valueOf());
      this.getNumberDays();
    },

    endDate(value) {
      this.request.endDate = this.$DATEFORMAT(new Date(value), "yyyy-MM-dd");
      this.getNumberDays();
    },

    endTime() {
      this.getNumberDays();
    },
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.plannedWorks(`${pageNumber}${itemPage}`);
    },

    handleEdit(data) {
      this.requestId = data.id;
      this.request.paySettingId = data.paySettingId;
      this.request.plannedTask = data.plannedTask;
      this.request.description = data.description;
      this.request.impact = data.impact;
      this.request.totalCustomersImpacted = data.totalCustomersImpacted;
      this.request.severity = data.severity;
      this.request.severityComment = data.severityComment;
      this.endDate = new Date(data.endDate);
      this.startDate = new Date(data.startDate);
      this.startTime = data.startTime;
      this.endTime = data.endTime;
      this.request.startDate = data.startDate;
      this.request.endDate = data.endDate;
      this.requestModal = true;
      this.enableEdit = true;
    },

    addRequest() {
      this.enableEdit = false;
      this.requestModal = true;
    },

    handleDelete(data) {
      this.dialogDel = true;
      this.overtimeId = data.id;
    },

    getNumberDays() {
      if (this.startTime !== "" && this.endTime !== "") {
        const setStartDate = this.$DATEFORMAT(
          new Date(this.startDate),
          "yyyy/MM/dd"
        );
        const setEndDate = this.$DATEFORMAT(
          new Date(this.endDate),
          "yyyy/MM/dd"
        );

        const firstDate = new Date(`${setStartDate} ${this.startTime}`);
        const secondDate = new Date(`${setEndDate} ${this.endTime}`);

        const DifferenceInTime = secondDate.getTime() - firstDate.getTime();

        const diffDays = Math.round(DifferenceInTime / (1000 * 3600 * 24));

        let diffInMilliSeconds = Math.abs(secondDate - firstDate) / 1000;

        const hours = Math.floor(diffInMilliSeconds / 3600);
        diffInMilliSeconds -= hours * 3600;

        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        if (diffDays > 2) {
          this.numberDays = 0;
          this.$toasted.error("Duration cannot exceed 2 days", {
            duration: 5000,
          });
          this.disableBtn = true;
        }

        if (diffDays <= 2) {
          if (Number.isNaN(hours) || Number.isNaN(minutes)) {
            this.numberDays = 0;
            this.disableBtn = true;
          } else {
            this.numberDays = `${hours} hour(s), ${minutes} minute(s)`;
            this.disableBtn = false;
          }
        }
      }
    },

    deleteDraft() {
      this.$_deleteOvertimeDraft(this.overtimeId).then((result) => {
        this.$toasted.success(result.message, { duration: 5000 });
        this.dialogDel = false;
        this.plannedWorks("&page=1&perPage=50");
      });
    },

    async plannedWorks(query) {
      this.loading = true;
      try {
        const response = await this.$_getPlannedWorks({
          userId: this.$AuthUser.id,
          query,
        });
        const requestData = response.data.requests;
        this.metaData = response.data.meta;

        const plannedWorks = requestData.map((v) => ({
          category: v.paySetting.nameOnPayslip,
          startDate: new Date(v.startDate).toISOString().replace(/.\d+Z$/g, ""),
          endDate: new Date(v.endDate).toISOString().replace(/.\d+Z$/g, ""),
          ...v,
        }));

        this.requests = plannedWorks;

        this.employeeId = response.data.employee.id;
        this.totalWorkHours = Math.ceil(response.data.work.totalWorkHours);
        this.totalWorkCount = Math.ceil(response.data.work.workCount);
        this.totalCustomersImpacted = Math.ceil(
          response.data.work.totalCustomersImpacted
        );
        this.getPayItem();
        this.loading = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    reload() {
      this.plannedWorks("&page=1&perPage=50");
    },
    async getPayItem() {
      try {
        const {
          data: { paySettings },
        } = await this.$_getOvertimePayItem({
          employeeId: this.employeeId,
        });
        this.items = paySettings.map((item) => ({
          id: item.paySettingId,
          name: item.paysetting.nameOnPayslip,
        }));
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },

    addPlannedWork(type) {
      if (!this.disableBtn) {
        this.formatTime();
        this.request.employeeId = this.$AuthUser.id;
        if (type === "draft") {
          this.request.preApprovalStatus = "draft";
        }
        const payLoad = {
          userId: this.$AuthUser.id,
          employeeId: this.$AuthUser.id,
          requests: [this.request],
        };
        this.$_addOverPreApprovalRequest(payLoad)
          .then((result) => {
            if (type === "draft") {
              this.$toasted.success("Draft saved successfully", {
                duration: 5000,
              });
            } else {
              this.$toasted.success(result.data.message, { duration: 5000 });
            }
            this.plannedWorks("&page=1&perPage=50");
            this.closeModal();
          })
          .catch(() => {
            this.$toasted.error("Error in saving request", { duration: 5000 });
            this.requestModal = false;
            this.scrollTop();
          });
      }
    },

    editPlannedWork(type) {
      if (!this.disableBtn) {
        this.formatTime();
        this.request.employeeId = this.$AuthUser.id;
        if (type === "draft") {
          this.request.preApprovalStatus = "draft";
        } else {
          this.request.preApprovalStatus = "pending";
        }
        this.$_updatePreApprovalRequest(this.request, {
          requestId: this.requestId,
        })
          .then((result) => {
            if (type === "draft") {
              this.$toasted.success("Draft edited successfully", {
                duration: 5000,
              });
            } else {
              this.$toasted.success(result.data.message, { duration: 5000 });
            }
            this.plannedWorks("&page=1&perPage=50");
            this.closeModal();
          })
          .catch(() => {
            this.$toasted.error("Error in editing request", { duration: 5000 });
            this.requestModal = false;
            this.scrollTop();
          });
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    closeModal() {
      this.request = {};
      this.startDate = "";
      this.startTime = "";
      this.endDate = "";
      this.endTime = "";
      this.numberDays = 0;
      this.disableBtn = true;
      this.requestModal = false;
    },

    formatTime() {
      const getStartHour = this.startTime.split(":");
      const getEndHour = this.endTime.split(":");
      // eslint-disable-next-line prefer-destructuring
      this.request.startHour = getStartHour[0];
      // eslint-disable-next-line prefer-destructuring
      this.request.startMinute = getStartHour[1];
      // eslint-disable-next-line prefer-destructuring
      this.request.endHour = getEndHour[0];
      // eslint-disable-next-line prefer-destructuring
      this.request.endMinute = getEndHour[1];
    },

    setTimeData(workHours) {
      const stringHrs = workHours.toString();
      const splitTime = stringHrs.split(".");

      let minutes;

      const hours = `${splitTime[0]} hour(s)`;

      if (typeof splitTime[1] === "undefined") {
        minutes = "";
      } else {
        minutes = `${(parseInt(`${splitTime[1]}`, 10) / 10) * 60} minute(s)`;
      }

      return `${hours} ${minutes}`;
    },
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
  },
  mounted() {
    this.plannedWorks("&page=1&perPage=50");
  },
};
</script>

<style>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.bg-ash {
  background-color: rgba(20, 23, 31, 0.08);
}
</style>
