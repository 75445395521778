<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <div style="width: 150px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Overtime
        </h1>
      </div>
    </div>
    <div class="px-3">
      <div class="mt-8">
        <Tab
          :tabs="['Planned Work', 'Overtime Pay']"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
        />
      </div>
      <PlannedWork
        v-if="currentTab === 'Planned Work'"
        :is-eligible="isEligible"
      />
      <OvertimePay
        v-if="currentTab === 'Overtime Pay'"
        :is-eligible="isEligible"
      />
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import PlannedWork from "./PlannedWork";
import OvertimePay from "./OvertimePay";

export default {
  name: "Overtime",
  components: {
    Tab,
    PlannedWork,
    OvertimePay
  },
  data() {
    return {
      currentTab: "Planned Work",
      isEligible: null
    };
  },
  methods: {
    getOvertimeEligibility() {
      this.$_getEmployeeEligibility(this.$AuthUser.id).then(({ data }) => {
        this.isEligible = data.data.enabledOvertime;
      });
    }
  },
  mounted() {
    this.getOvertimeEligibility();
  }
};
</script>
